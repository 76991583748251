import {
  Container,
  Content,
  Media,
  Paragraph,
  StyledLink,
  Title,
} from "./styles";
import Img from "gatsby-image";
import React from "react";
import { useImage } from "@queries/use-image";

const ContactInfoComponent = () => {
  const { getFluidImageNameAndExtension } = useImage();

  return (
    <Container>
      <Content>
        <Title>Entra em contacto</Title>
        <Paragraph>
          Estamos disponíveis para ti. <br />
          Contacta-nos através do número 123456789 <br />
          ou envia-nos um email para{" "}
          <StyledLink href="mailto:aprender@treetree2.org">
            aprender@treetree2.org
          </StyledLink>
        </Paragraph>

        <Paragraph>Também podes visitar-nos em:</Paragraph>
        <Paragraph>
          Rua Pereira e Sousa, 35, 1A <br />
          1350-235 Lisboa
        </Paragraph>
        <Paragraph>Até já.</Paragraph>
      </Content>

      <Media>
        <Img
          fluid={getFluidImageNameAndExtension("white-orange-mesh", "jpg")}
          alt="Rede"
        />
      </Media>
    </Container>
  );
};

export default ContactInfoComponent;
