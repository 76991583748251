import ContactInfo from "@sections/contact-info/contact-info-component";
import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Layout from "@components/layout";
import Newsletter from "@components/newsletter/newsletter-component";
import React from "react";
import Seo from "@components/seo";
import { useImage } from "@queries/use-image";

export default function Contactos() {
  const { getFluidImageNameAndExtension } = useImage();
  const seoImage = getFluidImageNameAndExtension("people-talking-group", "jpg");

  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace desktop={{ margin: 50 }} mobile={{ margin: 180 }} />

      <ContactInfo />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title="Contactar o TreeTree2"
        description="Entra em contacto connosco caso queiras saber mais sobre o TreeTree2 e os seus projetos."
        image={seoImage}
      />
    </Layout>
  );
}
