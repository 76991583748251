import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  max-height: 521px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    max-height: unset;
    align-items: flex-start;
  }
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
`;

export const StyledLink = styled.a`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b50000;
  text-decoration: none;
`;

export const Content = styled.div`
  flex: 1;
  max-width: 393px;

  & > ${Paragraph} + ${Paragraph} {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    & > p:nth-child(2) {
      margin-bottom: 60px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  margin-bottom: 13px;

  @media (max-width: 768px) {
    margin-bottom: 18px;
  }
`;

export const Media = styled.div`
  flex: 1;
  margin-left: 90px;
  max-height: 521px;

  @media (min-width: 769px) and (max-width: 1024px) {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;
